import axios from 'axios'
import { Message, MessageBox } from 'element-ui' // 项目用的element ui
import md5 from 'js-md5';
import QS from 'qs';
import { aes_encrypt,aes_decrypt } from '@/utils/aes.js'
import store from '@/store'
import router from '@/router'
import { dexWalletsApprove } from '@/api/markets'
import { compareVersion } from '@/api/user'
// 三按钮弹窗
import msgConfirm from '@/components/msgConfirm/index.js';

import { getTradingPairInfo } from '@/utils/methApi/tradingPairDefault'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

export const service = axios.create({
    timeout: 30000, // 超过30s提示超时
    baseURL: process.env.VUE_APP_BASE_API,
})

export const serviceDown = axios.create({
    timeout: 30000, // 超过30s提示超时
    baseURL: process.env.VUE_APP_BASE_API,
})

// 请求拦截器
service.interceptors.request.use(
    async config => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

        config.headers['x-light-token'] = '';
        if (store.getters.token) {
            config.headers['x-light-token'] = store.getters.token;
        }
        // channel
        var channel = 'light';
        config.headers['x-light-channel'] = channel;

        // timestamp
        var now = new Date();
        var timestamp = now.getTime();
        config.headers['x-light-timestamp'] = timestamp;

        // lang
        config.headers['x-light-language'] = localStorage.getItem('lang') || 'en';

        // version
        config.headers['x-light-version'] = store.getters.version;

        // sign
        // console.log('config',config);

        // console.log(process.env.VUE_APP_SIGN_KEY ,  QS.parse(config.data).data , channel , timestamp)
        // var sign=md5(process.env.VUE_APP_SIGN_KEY + config.data.data + channel + timestamp);
        var sign = md5(process.env.VUE_APP_SIGN_KEY + QS.parse(config.data).data + channel + timestamp);
        // console.log('sign',sign)
        config.headers['x-light-sign'] = sign;

        // ip
        if (store.getters.ip) {
            config.headers['x-light-ip'] = store.getters.ip;
        }

        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)
// 请求拦截器
serviceDown.interceptors.request.use(
    async config => {
        config.headers['Content-Type'] = 'application/json';

        config.headers['x-light-token'] = '';
        if (store.getters.token) {
            config.headers['x-light-token'] = store.getters.token;
        }
        // channel
        var channel = 'light';
        config.headers['x-light-channel'] = channel;

        // timestamp
        var now = new Date();
        var timestamp = now.getTime();
        config.headers['x-light-timestamp'] = timestamp;

        // lang
        config.headers['x-light-language'] = localStorage.getItem('lang') || 'en';

        // version
        config.headers['x-light-version'] = store.getters.version;

        // sign
        // console.log('config',config);

        // console.log(process.env.VUE_APP_SIGN_KEY ,  QS.parse(config.data).data , channel , timestamp)
        // var sign=md5(process.env.VUE_APP_SIGN_KEY + config.data.data + channel + timestamp);
        var sign = md5(process.env.VUE_APP_SIGN_KEY + config.data.data + channel + timestamp);
        // console.log('sign',sign)
        config.headers['x-light-sign'] = sign;

        // ip
        if (store.getters.ip) {
            config.headers['x-light-ip'] = store.getters.ip;
        }

        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
)

let messageAll;
// 响应拦截器
service.interceptors.response.use(
    response=>resSuccess(response),
    error => resError(error)
)
// 响应拦截器
serviceDown.interceptors.response.use(
    response=>resSuccess(response),
    error => resError(error)
)

// 登录、退出登录
var loginUrlList=['/Account/login','/Account/login_code','/Account/logout ']
// 发送错误日志
function sendError(response){
    const requestConfig = response.config;
    const requestUrl = requestConfig.url;
    const requestParams = requestConfig.params || requestConfig.data;
    
    var data = aes_encrypt({
        account:loginUrlList.includes(requestUrl)?aes_decrypt(decodeURIComponent(requestParams.slice(5))).account:store.getters.account,
        web:getTradingPairInfo('web'),
        currency:getTradingPairInfo('yenUnit1')+'_'+getTradingPairInfo('yenUnit2'),
        url:requestUrl,
        param:requestParams,
        result:response.data,
        http_code:response.status,
    })
    var obj = { data }
    service({
        url: '/System/request_error_log',
        method: 'post',
        data: QS.stringify(obj),
        timeout: 30 * 1000, // 超过30s提示超时
    })
}

// 请求成功回调
function resSuccess(response){
    if(response.config.url=='/System/request_error_log')return

    // 接收后台参数状态
    const res = response.data;
    // console.log(res,888)
    let resMessage = (res.error && res.error.message) || res.message || res.msg
    let message = resMessage || window.vm.$t('tip.unknownErr') || '未知错误';

    // 请求记录日志
    sendError(response)
    // 如果是登录、退出登录接口 默认发送记录日志
    // if(loginUrlList.includes(response.config.url)){
    //     sendError(response)
    // }
    // yc4191、JunSato1224 错误请求 日志特殊记录
    // var requestParams=response.config.params || response.config.data
    // if(
    //     res.code != 1000 &&
    //     ((loginUrlList.includes(response.config.url)?aes_decrypt(decodeURIComponent(requestParams.slice(5))).account:store.getters.account)=='yc4191'||
    //     (loginUrlList.includes(response.config.url)?aes_decrypt(decodeURIComponent(requestParams.slice(5))).account:store.getters.account)=='JunSato1224')
    // ){
    //     sendError(response)
    // }

    if (res.code == 1000) {
        return res;
    }
    // else if(res.code==1002){
    //     // 版本号错误
    // }
    else if (res.code == 1003) {
        // 可不需要升级继续使用
        MessageBox.confirm(res.data.version + ' ' + message + '<div style="color:#E65B1B">' + window.vm.$t('tip.update') + res.data.content + '</div>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.update1'),
            cancelButtonText: window.vm.$t('common.refused'),
            center: true,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            showClose: false,
        }).then(() => {
            // 强制刷新
            location.reload(true)
        }).catch(() => {
            // 忽略
            compareVersion({
                version: store.getters.version,
                ignore_version: res.data.version,
            }).then(r => {
                localStorage.setItem('ignore_version', res.data.version)
            })
        });
    } else if (res.code == 1004) {
        // 需要强制升级
        MessageBox.confirm(res.data.version + ' ' + message + '<div style="color:#E65B1B">' + window.vm.$t('tip.update') + res.data.content + '</div>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.update2'),
            center: true,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            showClose: false,

            showCancelButton: false,
        }).then(() => {
            // 强制刷新
            location.reload(true)
        })
    }
    else if (
        res.code == 2000 || 
        res.code == 2001 || 
        res.code == 2002 || //token失效
        res.code == 2014 || 
        res.code == 2020 || //检测到您在别的设备登录
        res.code == 2053//无效账号
    ) {
        // 发送错误日志
        // sendError(response)
        // token过期、其他客户端登录
        store.dispatch('user/resetToken').then(() => {
            router.push({
                path: '/login',
                query: { redirect: window.location.pathname + window.location.search }
            })
            if (messageAll) {
                messageAll.close()
            }
            messageAll = Message({
                message,
                type: 'error',
                duration: 3 * 1000,
                customClass: 'shotMsg',
            });
        })
    } else if (res.code == 2015) {
        // 发送错误日志
        // sendError(response)
        MessageBox.confirm(window.vm.$t('tip.ipErr'), window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.loginAgain'),
            cancelButtonText: window.vm.$t('common.back'),
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true
        }).then(() => {
            store.dispatch('user/logout').then(res => {
                router.push({
                    path: '/login',
                    query: { redirect: window.location.pathname + window.location.search }
                })
            })
        }).catch(err => {
            MessageBox.close()
        })
    } else if (res.code == 2016) {
        // 存储安全登录邮箱
        store.commit('user/SET_SAFE_EMAIL', res.data)
        localStorage.setItem('addAccount', 1)
        // 开启安全登录验证
        router.push({
            path: '/safeLogin'
        })
    }

    else if (res.code == 2092) {
        // 未绑定该交易所钱包 回到账号列表
        router.push({
            path: '/account',
        })
        if (messageAll) {
            messageAll.close()
        }
        messageAll = Message({
            message,
            type: 'error',
            duration: 3 * 1000,
            customClass: 'shotMsg',
        });
        // return res
    } else if (res.code == 2099) {
        MessageBox.confirm(message, window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.back'),
            // showConfirmButton:false,
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            cancelButtonText: window.vm.$t('common.back'),
            showCancelButton: false,
        }).then(res => {
            router.push({
                path: '/stocks',
            })
            // router.go(-1)
            MessageBox.close()
        }).catch(err => {
            MessageBox.close()
        })
    }

    // 授权
    else if (res.code == 2107) {
        // 全没授权
        return MessageBox.confirm(message, window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.toAuthorize'),
            // showConfirmButton:false,
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            cancelButtonText: window.vm.$t('common.back'),
        }).then(r => {
            return MessageBox.confirm(window.vm.$t('tip.confirmAuthorize') + res.data, window.vm.$t('tip.tips'), {
                confirmButtonText: window.vm.$t('common.authorize'),
                // showConfirmButton:false,
                center: true,
                showClose: false,
                customClass: 'deleteOk',
                cancelButtonText: window.vm.$t('common.back'),
            }).then(r => {
                // 去授权
                return {
                    data: {
                        data: res.data,
                        code: 2107
                    },
                    decrypt: '0'
                }
            })
        }).catch(err => {
            MessageBox.close()
        })
    }
    else if (res.code == 2108) {// 已停用 dex手动下单
        // 手动下单2108
        // 资金钱包未授权->授权资金钱包
        // 未授权提示
        MessageBox.confirm(message, window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.toAuthorize'),
            // showConfirmButton:false,
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            cancelButtonText: window.vm.$t('common.back'),
        }).then(r => {
            // 授权二次确认
            MessageBox.confirm(window.vm.$t('tip.confirmAuthorize') + res.data, window.vm.$t('tip.tips'), {
                confirmButtonText: window.vm.$t('common.authorize'),
                // showConfirmButton:false,
                center: true,
                showClose: false,
                customClass: 'deleteOk',
                cancelButtonText: window.vm.$t('common.back'),
            }).then(r => {
                // 提示授权中。。
                Message({
                    message: window.vm.$t('tip.processing'),
                    type: 'success',
                    center: true,
                    duration: 3 * 1000,
                    customClass: 'shotMsg',
                });
                // 授权代币请求
                dexWalletsApprove({
                    web: getTradingPairInfo('web'),
                    account: store.getters.account,
                    approve_token: res.data.replace(',', '_'),//代币
                    is_master_approve: 1,
                    group_id: '-999',
                }).then(re => {
                    MessageBox.confirm(window.vm.$t('tip.authorizeSuc'), window.vm.$t('tip.tips'), {
                        confirmButtonText: window.vm.$t('common.confirm'),
                        // showConfirmButton:false,
                        center: true,
                        showClose: false,
                        customClass: 'deleteOk',
                        // cancelButtonText: window.vm.$t('common.back'),
                        showCancelButton: false,
                    }).then(r => {
                        MessageBox.close()
                    }).catch(err => {
                        MessageBox.close()
                    })
                })
            })
        }).catch(err => {
            MessageBox.close()
        })
    }
    else if (res.code == 2109) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2109,
            })
        }
        // 部分授权失败2109
        // 返回 去分拨->交易钱包页面打开资金划转弹窗(新) 直接授权 忽略，继续操作

        // 资金划转弹窗(新)：资金钱包地址、交易钱包地址（按钮查看地址）、链、币种、数量、 返回、分拨并授权
        // 查看地址弹窗：地址列表、返回按钮

        return msgConfirm.confirm(message + '<div style="color:#E65B1B">' + window.vm.$t('tip.authorizeErr2') + '</div>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.toAllocation'),
            nowButtonText: window.vm.$t('common.directAuthorize'),
            nextButtonText: window.vm.$t('common.ignore'),
            cancelButtonText: window.vm.$t('common.back'),
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true,
            showNowButton: true,
        }).then((r) => {
            // 不解码
            if (r == 'confirm') {
                // 分拨，资金划转->分拨并授权->授权成功，继续操作
                return {
                    data: {
                        data: res.data,
                        code: 2109,
                        type: 'confirm',
                    },
                    decrypt: '0'
                }
            } else if (r == 'now') {
                // 直接授权
                // 授权代币请求
                return {
                    data: {
                        data: res.data.approve_token,
                        code: 2109,
                        type: 'now',
                    },
                    decrypt: '0'
                }
            } else if (r == 'next') {
                // 忽略，继续操作
                return {
                    data: {
                        type: 'ignore',
                        code: 2109,
                    },
                    decrypt: '0'
                }
            }
        }).catch(err => {
            msgConfirm.close()
        })
    }
    else if (res.code == 2110) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2110,
            })
        }
        // 资金钱包授权失败2110
        // 返回 去查看->交易钱包页面
        msgConfirm.confirm(message + '<br/><span style="color:#E65B1B">' + window.vm.$t('tip.mainAuthorizeErr') + '</span>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.toErrLog'),
            nextButtonText: window.vm.$t('common.toSeeBtn'),
            cancelButtonText: window.vm.$t('common.back'),
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true
        }).then((r) => {
            if (r == 'confirm') {
                // 跳转错误日志 交易所、关键词-钱包批量授权
                router.push({
                    path: '/robotlog',
                    query: {
                        web: getTradingPairInfo('web'),
                        exchange: getTradingPairInfo('exchange'),
                        searchValue: '钱包批量授权',
                    }
                })
            } else if (r == 'next') {
                // 去分拨->交易钱包页面打开分拨弹窗
                router.push({
                    path: '/subManage',
                    query: {
                        web: getTradingPairInfo('web'),
                        yenUnit1: getTradingPairInfo('yenUnit1'),
                        yenUnit2: getTradingPairInfo('yenUnit2'),
                    }
                })
            }
        }).catch(err => {
            msgConfirm.close()
        })
    }
    else if (res.code == 2171) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2171,
            })
        }
        // 交易钱包授权失败2171
        // 返回 去分拨->交易钱包页面打开分拨弹窗
        msgConfirm.confirm(message + '<br/><span style="color:#E65B1B">' + window.vm.$t('tip.authorizeErr') + '</span>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.toErrLog'),
            nextButtonText: window.vm.$t('common.toAllocation'),
            cancelButtonText: window.vm.$t('common.back'),
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true
        }).then((r) => {
            if (r == 'confirm') {
                // 跳转错误日志 交易所、关键词-钱包批量授权
                router.push({
                    path: '/robotlog',
                    query: {
                        web: getTradingPairInfo('web'),
                        exchange: getTradingPairInfo('exchange'),
                        searchValue: '钱包批量授权',
                    }
                })
            } else if (r == 'next') {
                // 去分拨->交易钱包页面打开分拨弹窗
                router.push({
                    path: '/subManage',
                    query: {
                        web: getTradingPairInfo('web'),
                        yenUnit1: getTradingPairInfo('yenUnit1'),
                        yenUnit2: getTradingPairInfo('yenUnit2'),
                        openDistribution: '1',//交易钱包页面打开分拨弹窗
                        gas_token: res.data.gas_token
                    }
                })
            }
        }).catch(err => {
            msgConfirm.close()
        })
    }
    else if (res.code == 2172) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2172,
            })
        }
        // 部分授权失败2172
        // 返回 去分拨->交易钱包页面打开资金划转弹窗(新)
        // 查看地址弹窗：地址列表、返回按钮

        return msgConfirm.confirm(message + '<div style="color:#E65B1B">' + window.vm.$t('tip.authorizeErr3') + '</div>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.allotAuthorize'),
            nextButtonText: window.vm.$t('common.ignore'),
            cancelButtonText: window.vm.$t('common.back'),
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            dangerouslyUseHTMLString: true,
        }).then((r) => {
            // 不解码
            if (r == 'confirm') {
                // 分拨，资金划转->分拨并授权->授权成功，继续操作
                return {
                    data: {
                        data: res.data,
                        code: 2172,
                        type: 'confirm',
                    },
                    decrypt: '0'
                }
            } else if (r == 'next') {
                // 忽略，继续操作
                return {
                    data: {
                        type: 'ignore',
                        code: 2109,
                    },
                    decrypt: '0'
                }
            }
        }).catch(err => {
            msgConfirm.close()
        })
    }
    else if (res.code == 2173) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2173,
            })
        }
        // 手动授权失败2173
        // 返回 去分拨->交易钱包页面打开资金划转弹窗(新)
        // 查看地址弹窗：地址列表、返回按钮
        return MessageBox.confirm(message + '<div style="color:#E65B1B">' + window.vm.$t('tip.authorizeErr2') + '</div>', window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.allotAuthorize'),
            // showConfirmButton:false,
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            cancelButtonText: window.vm.$t('common.back'),
            dangerouslyUseHTMLString: true,
        }).then((r) => {
            // 不解码
            // 分拨，资金划转->分拨并授权->授权成功，继续操作
            return {
                data: {
                    data: res.data,
                    code: 2173,
                },
                decrypt: '0'
            }
        }).catch(err => {
            MessageBox.close()
        })
    }
    else if (res.code == 2174) {
        // 如果是钱包管理页面，直接给出报错提示
        if (window.vm.$route.path == '/subManage') {
            return Promise.reject({
                message,
                code: 2174,
            })
        }
        // 授权失败2174-合约未录入系统
        return MessageBox.confirm(message, window.vm.$t('tip.tips'), {
            confirmButtonText: window.vm.$t('common.confirm2'),
            // showConfirmButton:false,
            center: true,
            showClose: false,
            customClass: 'deleteOk',
            // cancelButtonText: window.vm.$t('common.back'),
            showCancelButton: false,
        }).then((r) => {
            // 授权失败 查看日志
            // router.push({
            //     path: '/robotlog',
            //     query: {
            //         searchValue: '钱包批量授权',
            //     }
            // })
        }).catch(err => {
            MessageBox.close()
        })
    }
    else if (res.code == 2176) {
        // 支付续费钱包未生成2176
        // 错误提示 根据返回在具体请求位置提示
        return Promise.reject(res
            // new Error(res.message || (res.error &&res.error.message) || '未知错误')
        );
    }

    else {
        if (messageAll) {
            messageAll.close()
        }
        messageAll = Message({
            message,
            type: 'error',
            duration: 3 * 1000,
            customClass: 'shotMsg',
        });

        // 如果未知错误，则提示系统正忙，并请求error_log接口
        if (!resMessage) {
            // 发送错误日志
            // sendError(response)
        }

        console.log('拦截器打印错误:', res);
        // 这里可以设置后台返回状态码是500或者是其他,然后重定向跳转
        // if(res.ErrorCode == 500) {
        //     router.push('/login')
        // }
        return Promise.reject(res
            // new Error(res.message || (res.error &&res.error.message) || '未知错误')
        );
    }
}
// 请求失败回调
function resError(error){
    console.log('服务器错误信息:', error);
    if (error.response) {
        // error.response有错误信息,是接口错误,不是取消请求
        // 获取错误码,弹出提示信息,reject()
        let code = error.response.status;

        if (messageAll) {
            messageAll.close()
        }
        messageAll = Message({
            message: window.vm.$t('tip.serverErr') + code,
            type: 'error',
            duration: 3 * 1000,
            customClass: 'shotMsg',
        });
        return Promise.reject(error.message);
    }
    else {
        // 是取消请求/请求超时/网络异常
        // if(messageAll){
        //     messageAll.close()
        // }
        // messageAll=Message({
        //     message: window.vm.$t('tip.timeout'),
        //     type: 'error',
        //     duration: 3 * 1000,
        //     customClass: 'shotMsg',
        // });
        // 直接reject
        return Promise.reject(error.message);
    }
}
// export default service;