<!-- 局部显示的消息提示框 -->
<template>
    <div class="localToast" :class="classStr">
        <!-- 客服正在努力回复中，请稍等~ -->
        {{$t('common.replyWait')}}
    </div>
</template>
<script>
export default {
    name: 'localToast',
    data() {
        return {
            // message: '',
            classStr:'',
        };
    },
    methods: {
        open(){//message
            // this.message=message
            this.classStr='enterActive'
            setTimeout(()=>{
                this.classStr='leaveActive'
            },2000)
        }
    },
};
</script>
<style lang="stylus" scoped>
.localToast{
    width: fit-content;
    min-width: 96px;
    min-height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 8px 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: center;
    word-break: break-all;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
}
.enterActive{
    transition: opacity 0.3s ease-out;
    opacity: 1;
}
.leaveActive{
    transition: opacity 0.3s ease-in;
    opacity: 0;
}
</style>