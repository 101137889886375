// 市场相关
import {
    getWebList,
    getCollectList,
} from '@/api/markets'
import {
    getWebListAll
} from '@/api/robot'
// import {getVipList,getPayInfo} from '@/api/user'
import { getVipList } from '@/api/user'

const state = {
    // ip地址白名单
    ipWhitelist:'47.244.207.193,47.242.71.169,47.242.19.217,8.210.14.29,47.74.20.52',
    
    webList: [],
    webListAll: JSON.parse(sessionStorage.getItem("web-list")),
    collectList: [],
    vipList: {},
    // K线、NFT漂浮窗是否显示
    showKlineOrOpensea: false,
    positionX2: 0,  // 元素的横坐标
    positionY2: 0,  // 元素的纵坐标
    isMouseDown2: false,  // 是否处于鼠标按下状态
    isDragging2: false,  // 是否正在拖拽
    // 拖拽
    mouseOffsetX2: 0,  // 鼠标点击位置和元素左上角的偏移量
    mouseOffsetY2: 0,
    mouseDownX2: 0,  // 鼠标点击的初始位置
    mouseDownY2: 0,

    // sol链下的交易所
    solWebList: ['jupitersol', 'orcasol', 'raydiumsol', 'raydiumsolv3'],
}

const mutations = {
    SET_WEB_LIST: (state, webList) => {
        state.webList = webList
    },
    SET_WEB_LIST_ALL: (state, webListAll) => {
        state.webListAll = webListAll
    },
    SET_COLLECT_LIST: (state, collectList) => {
        state.collectList = collectList
    },
    SET_VIP_LIST: (state, vipList) => {
        state.vipList = vipList
    },

    // k线、opensea拖拽
    SET_SHOW_KLINE_OR_OPENSEA: (state, showKlineOrOpensea) => {
        state.showKlineOrOpensea = showKlineOrOpensea
    },
    SET_POSITION_X: (state, positionX) => {
        state.positionX2 = positionX
    },
    SET_POSITION_Y: (state, positionY) => {
        state.positionY2 = positionY
    },
    SET_IS_MOUSE_DOWN: (state, isMouseDown) => {
        state.isMouseDown2 = isMouseDown
    },
    SET_IS_DRAGGING: (state, isDragging) => {
        state.isDragging2 = isDragging
    },
    SET_MOUSE_OFFSET_X: (state, offsetX) => {
        state.mouseOffsetX2 = offsetX;
    },
    SET_MOUSE_OFFSET_Y: (state, offsetY) => {
        state.mouseOffsetY2 = offsetY;
    },
    SET_MOUSE_DOWN_X: (state, downX) => {
        state.mouseDownX2 = downX;
    },
    SET_MOUSE_DOWN_Y: (state, downY) => {
        state.mouseDownY2 = downY;
    },
}
const actions = {
    getList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            getWebList({ account: rootState.user.account }).then(res => {
                commit('SET_WEB_LIST', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getWebListAll({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            getWebListAll({ account: rootState.user.account }).then(res => {
                var jsonStr = JSON.stringify(res)
                sessionStorage.setItem("web-list", jsonStr)
                commit('SET_WEB_LIST_ALL', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getCollectList({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            getCollectList({ account: rootState.user.account }).then(res => {
                commit('SET_COLLECT_LIST', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // getPayInfo({commit,rootState}){
    //     return new Promise((resolve, reject) => {
    //         getPayInfo({account:rootState.user.account}).then(res=>{
    //             commit('SET_VIP_LIST',res.fee)
    //             resolve(res)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },
    getVipList({ commit }) {
        return new Promise((resolve, reject) => {
            getVipList({}).then(res => {
                commit('SET_VIP_LIST', res)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}