import {http,http_no_aes} from '@/utils/http'

export const login = data => http('/Account/login',data,'post')

// 安全登录
export const safeLoginCode = data => http('/Account/login_code',data,'post')

export const register = data => http('/Account/register',data,'post')

export const getInfo = data => http('/Account/get_account_info',data,'post')

export const logout = data => http('/Account/logout',data,'post')
// 自动续费开关
export const paySet = data => http('/Wallet/pay_set',data,'post')


// 忘记密码/修改密码
export const updatePassword = data => http('/Account/update_password',data,'post')
// 绑定/修改手机号
// export const updateTel = data => http('/Account/update_tel',data,'post')
// 发送验证码
// export const sendCode = data => http('/Account/send_sms',data,'post')
// 绑定/修改邮箱
export const updateEmail = data => http('/Account/update_email',data,'post')
// 发送邮箱验证码
export const sendEmail = data => http('/Account/send_email',data,'post')
// 安全登录开关
export const setSafeLogin = data => http('/Account/safe_login_set',data,'post')
// 多设备登录开关
export const setMultiDeviceLogin = data => http('/Account/multi_login_set',data,'post')
// IP验证开关
// export const setIPCheck = data => http('/Account/ip_check_set',data,'post')
// 解绑电报
export const cancelTelegramBind = data => http('/Account/cancel_telegram_bind',data,'post')

// 钱包
// 3.1获取付费信息接口
export const getPayInfo = data => http('/Wallet/get_pay_info',data,'post')
// 修改套餐接口
export const setPackage = data => http('/Wallet/update_package',data,'post')
// 套餐续费接口
export const pay = data => http('/Wallet/pay',data,'post')
// 3.3获取充值地址接口
export const getRechargePath = data => http('/Wallet/recharge',data,'post')
// 3.4提现申请接口
export const withdraw = data => http_no_aes('/Wallet/withdraw',data,'post')
// 3.5获取申请提现记录接口
export const getWithdrawRecord = data => http('/Wallet/get_withdraw_record',data,'post')
// 获取续费记录-充值
export const getPayRecord = data => http('/Wallet/pay_record',data,'post')


// 官网
// 获取套餐详情
export const getVipList = data => http('/Wallet/get_vip_list',data,'post')

// 版本升级判定
export const compareVersion = data => http_no_aes('/System/check_version',data,'post')