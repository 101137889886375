import CryptoJS from 'crypto-js';

function CryptoJSparse(key){
    return CryptoJS.enc.Utf8.parse(key)
}

export function aes_encrypt(clean_str) {
    var words=JSON.stringify(clean_str)
    // console.log(words)
    
    var ciphertext = CryptoJS.AES.encrypt(words, CryptoJSparse(process.env.VUE_APP_key), {
        iv: CryptoJSparse(process.env.VUE_APP_iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
        });
        
        // console.log('aes_encrypt',ciphertext.toString())
        return ciphertext.toString();
    }

//aes 128 解密
export function aes_decrypt(secret_str) {
    var bytes = CryptoJS.AES.decrypt(secret_str.toString(), CryptoJSparse(process.env.VUE_APP_key), {
        iv: CryptoJSparse(process.env.VUE_APP_iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    var data=bytes.toString(CryptoJS.enc.Utf8)
    try{
        var backData=JSON.parse(data)
    }catch{
        var backData=data
    }
    return backData;
    // return bytes.toString(CryptoJS.enc.Utf8);
}