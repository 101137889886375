import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'wowjs/css/libs/animate.css'

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

// 移动端判定
function mobileVer(){
  const ua = navigator.userAgent.toLowerCase();
  const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    ua
  );
  store.dispatch('app/switchIsMobile',t1?'1':'0')
}
mobileVer()
window.addEventListener('resize',() => {
  setTimeout(() => {
      mobileVer()
  }, 0);
})

// rem适配
import './utils/rem';

Vue.config.productionTip = false;

import 'element-ui/lib/theme-chalk/index.css';
// element按需引入
import {
  Table,
  TableColumn,
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  CheckboxGroup,
  Checkbox,
  Switch,
  Select,
  Option,
  Button,
  DatePicker,
  TimePicker,
  Popover,
  Tooltip,
  MessageBox,
  Alert,
  Notification,
  Loading,
  Icon,
  Message,
  Collapse,
  CollapseItem,
  Image,
  InfiniteScroll,
  Empty,
  Steps,
  Step,
  Form,
  FormItem,
  Slider,
} from 'element-ui';

Vue.use(Slider);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Image);
Vue.use(InfiniteScroll);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.use(Empty);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Form);
Vue.use(FormItem);


import 'vant/lib/index.css';
// vant按需引入
import {
	ActionSheet,
	Search,
	IndexBar, IndexAnchor,
	Cell,
	Calendar,
	Popup,
	DatetimePicker,
	Swipe, SwipeItem
} from 'vant';
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Cell);
Vue.use(Calendar);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Swipe);
Vue.use(SwipeItem);

import '@/assets/style/common.styl';
import '@/assets/style/theme/dark.css';
import '@/assets/style/theme/light.css';

// 动态meta
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);

// 国际化
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
let lang = 'en'
if (localStorage.getItem('lang')) {
	lang = localStorage.getItem('lang');
}
import locale from 'element-ui/lib/locale';
// element组件内置语言 https://element.eleme.cn/#/zh-CN/component/i18n
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import koLocale from 'element-ui/lib/locale/lang/ko'
import zh from './utils/lang/zh.js'
import en from './utils/lang/en.js'
import korean from './utils/lang/korean.js'
const i18n = new VueI18n({
    locale: lang,
    messages: {
		'zh': Object.assign(zh, zhLocale),
		'en': Object.assign(en, enLocale),
		'ko': Object.assign(korean, koLocale),
    },
});
locale.i18n((key, value) => i18n.t(key, value))

// // 新手教程pdf预加载
// import bps_zh from "@/assets/pdfFile/toturial_zh.pdf"
// import bps_en from "@/assets/pdfFile/toturial_en.pdf"
// import bps_ko from "@/assets/pdfFile/toturial_ko.pdf"
// var pdfFile=lang=='zh'? bps_zh:
// lang=='ko'? bps_ko:bps_en  
// // 新页面打开
// Vue.prototype.$pdf = pdfFile;

window.vm=new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
	// 预渲染
	mounted() {
    document.dispatchEvent(new Event('render-event'))
		// document.dispatchEvent(new Event("custom-render-trigger"));
  },
}).$mount('#app');

document.title=window.vm.$t('common.title')