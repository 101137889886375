// 市场相关接口
import {http,http_no_aes,http_longtime,http_no_aes_longtime} from '@/utils/http'

// 获取交易所列表
export const getWebList = data => http_no_aes('/Markets/get_web_list',data,'post')

// 手动操盘相关
// K线图
export const getKline = data => http_no_aes_longtime('/Markets/get_kline',data,'post')
// 获取盘口
export const getPankou = data => http_no_aes_longtime('/Markets/get_pankou',data,'post')
// 高级盘口
export const getPankouHigh = data => http_no_aes_longtime('/Markets/get_pankou_high_level',data,'post')
// 3.9获取账户某交易对余额信息接口
export const getCurrencyBalance = data => http('/Wallet/get_currency_balance',data,'post')
// 获取机器人最新状态日志
export const getRuntimeLog = data => http_no_aes('/Markets/get_runtime_log',data,'post')

// 收藏列表
export const getCollectList = data => http('/Account/collect_list',data,'post')
// 收藏
export const collect = data => http_no_aes('/Account/collect',data,'post')
// 取消收藏
export const collectCancel = data => http_no_aes('/Account/collect_cancel',data,'post')

// 交易相关
// 1.1手动下单接口
export const tradeCoin = data => http_no_aes('/Trade/trade_coin',data,'post')
// 1.3cex批量下单接口
export const batchTradeCoin = data => http('/Trade/batch_trade_coin',data,'post')
// 1.4cex批量撤单接口
export const batchTradeCancel = data => http('/Trade/batch_trade_cancel',data,'post')

// 1.5cex订单列表接口
export const getOrdersList = data => http_longtime('/Trade/trade_open_orders',data,'post')
// 1.4cex手动撤单接口
export const tradeCancel = data => http_no_aes('/Trade/trade_cancel',data,'post')

// 获取当前盘口机器人列表
export const getRobotList = data => http('/Markets/get_robot_list_alone',data,'post')

// 量化工具dex
// 2.7dex交易量测算接口
export const calNumberDex = data => http_no_aes('/Markets/cal_number_dex',data,'post')
// 1.6dex批量交易接口
export const batchTradeDex = data => http_no_aes('/Trade/batch_trade_dex',data,'post')
// 新增token合约
export const addToken = data => http_no_aes('/Markets/add_new_token',data,'post')


// 3.11dex查询账户交易钱包资产信息接口
// export const attachedWalletBalance = data => http('/Wallet/attached_wallet_balance',data,'post')

// 3.16dex钱包手动授权接口
export const dexWalletsApprove = data => http_no_aes_longtime('/Wallet/dex_wallets_approve',data,'post')

// 链名列表
export const getChain = data => http_no_aes('/Markets/get_dex_chain',data,'post')

// 交易钱包管理
// 3.12dex账户交易钱包资产刷新接口
export const attachedWalletBalanceUpdate = data => http_no_aes_longtime('/Wallet/attached_wallet_balance_update',data,'post')
// 3.13dex创建账户交易钱包接口
export const createDexWallets = data => http_no_aes_longtime('/Wallet/create_dex_wallets',data,'post')
// 3.14dex分拨资金接口
export const dexDeliverToken = data => http_no_aes('/Wallet/dex_deliver_token',data,'post')
// 3.15dex归集资金接口
export const dexCollectToken = data => http_no_aes('/Wallet/dex_collect_token',data,'post')
// 获取dex钱包信息接口
export const getWallets = data => http('/Wallet/dex_stat_info',data,'post')
// 获取交易钱包列表
export const getSubWalletsList = data => http('/Wallet/get_attached_wallets',data,'post')
// dex资金钱包单转交易钱包、交易钱包互转转账
export const subTransfer = data => http('/Wallet/dex_transfer',data,'post')
// dex交易钱包启用、禁用接口
export const setAttached = data => http('/Wallet/attached_set',data,'post')
// dex交易钱包导入接口
export const batchImport = data => http('/Wallet/batch_import',data,'post')
// dex交易钱包批量启用、禁用、删除接口
export const setAttachedAll = data => http('/Wallet/attached_set_all',data,'post')

// 资金钱包刷新接口
export const mainWalletBalanceUpdate = data => http_no_aes_longtime('/Wallet/main_wallet_balance_update',data,'post')

// 后台钱包数据刷新
export const dexWalletStatWeb = data => http_no_aes('/wallet/dex_wallet_stat_web',data,'post')

// dex分拨资金并授权接口
export const dexCollectTokenAppoint = data => http('/Wallet/dex_deliver_token_approve',data,'post')

// dex交易记录
export const getDexOrders = data => http('/Trade/dex_orders',data,'post')
// cex交易记录
export const getCexOrders = data => http('/Trade/cex_orders',data,'post')

// 获取NFT资产详情列表
export const getNFTList = data => http_no_aes('/Wallet/nft_list',data,'post')
export const getNFTCollection = data => http('/Markets/get_nft_collection',data,'post')

// 交易钱包地址导出
export const getWalletsList = data => http('/Wallet/wallets_export',data,'post')

// 将钱包转移至指定分组内
export const transferWalletGroup = data => http('/Wallet/transfer_dex_wallet_group',data,'post')
// 创建子钱包分组
export const createWalletGroup = data => http('/Wallet/create_dex_wallet_group',data,'post')
// 创建子钱包分组
export const delWalletGroup = data => http('/Wallet/delete_dex_wallet_group',data,'post')
// 获取钱包分组列表-含全部-999
export const getGroups = data => http('/Wallet/get_groups',data,'post')

// 获取分拨归集状态
export const getScheduleData = data => http_no_aes('/wallet/get_schedule_data',data,'post')