// const TokenKey = 'Admin-Token'

export function getToken() {
  return localStorage.getItem("light_token");
}

export function setToken(token) {
  try {
    localStorage.setItem('light_token', token)
  } catch (err) {
    // 处理空间不足的情况
    localStorage.removeItem("light_token");
    localStorage.setItem('light_token', token)
  };
}

export function removeToken() {
  return localStorage.removeItem("light_token");
}

// 用户账号
export function getAccount() {
  return localStorage.getItem("light_account");
}

export function setAccount(account) {
  try {
    localStorage.setItem('light_account', account)
  } catch (err) {
    // 处理空间不足的情况
    localStorage.removeItem("light_account");
    localStorage.setItem('light_account', account)
  };
}

export function removeAccount() {
  return localStorage.removeItem("light_account");
}